<template lang="pug">
    layout_page
        section.header.services_header.font_smooth
            .bg.image
            .column.flex_content-vertical.ai_center.jc_center
                .structure
                .margin-bottom--md.anime.fadeInBottom.one
                    h1.text--xxxl.text--uppercase.text--ls-sm Servicios #[span &] Fabricación
                .anime.fadeInBottom.two
                    p.text--uppercase.text--ls-sm Soluciones en contrucción con acero
            i.scroll_marker.anime.fadeInBottom.three
        section.services
            .column
                .service(v-for="service in allServices" :key="service.pk")
                    .grid.grid-phi
                        div
                            .wrapper
                                .service_image
                                    img(:src="service.img|ress" :alt="service.img.description")
                        div
                            .wrapper
                                .sec_title
                                    span {{ service.pk }}
                                    h3 {{ service.type }}
                                .margin-bottom--xxl
                                    h2.text--xxl {{ service.name }}
                                .margin-bottom--xxl
                                    p {{ service.short_description }}
                                slug-link(
                                    v-if="service.description"
                                    route="modal_service"
                                    :name="service.name"
                                    :pk="service.pk"
                                ).btn.btn--secondary
                                    span Conoce más
                                a(
                                    v-if="service.externalLink"
                                    :href="service.externalLink"
                                    :alt="service.name"
                                    target="_blank"
                                ).btn.btn--secondary
                                    span Conoce más
        transition(name="modal_transition")
            router-view
</template>

<script>
import Page from '@/views/layouts/Page'
import anime from 'animejs'
import SlugLink from '@/components/SlugLink'

export default {
    name: 'page_services',
    components: {
        layout_page: Page,
        SlugLink
    },
    metaInfo() {
        return {
            title: 'Servicios',
            meta:[
                {vmid: 'dtn', name:'description', content: 'Soluciones en contrucción con acero'},
                {vmid: 'kw', name:'keywords', content: 'La fragua, constructora, servicios, construcción con acero, estructuras metálicas, grúas, marcos para túneles, armaduras, estructuras, losacero, lámina cubierta, generadores eléctricos, maquinaria para construcción'},
                {vmid: 'inm', itemprop:'name', content: 'La Fragua | Servicios'},
                {vmid: 'idtn', itemprop:'description', content: 'Soluciones en contrucción con acero'},
                {vmid: 'ptt', property:'og:title', content: 'La Fragua | Servicios'},
                {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios'},
                {vmid: 'pdtn', property:'og:description', content: 'Soluciones en contrucción con acero'},
                {vmid: 'tdtn', name:'twitter:description', content: 'Soluciones en contrucción con acero'},
                {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Servicios'}
            ]
        }
    },
    methods: {
        initAnimations(curtain_shown) {
            let el = this.$el
            let timeline = anime.timeline()
            setTimeout(() => {
                if (!curtain_shown) {
                    timeline.add({
                        targets: el.querySelector('section.header'),
                        scale: [0.8, 1],
                        easing: 'easeOutExpo'
                    })
                }
                timeline
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.one'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.two'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.three'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
            }, curtain_shown ? 0 : 2400)
        }
    },
    mounted() {
        this.initAnimations(this.$store.getters.isCurtainShown)
        this.$store.commit('desactiveCurtain')
    },
    computed: {
        allServices() {
            return this.$store.getters.allServices
        }
    },
    watch: {
        '$route' (to, from) {
            if (to.name == 'page_services') {
                this.initAnimations(true)
            }
        }
    }
}
</script>
